import React from "react";
import NewsBoxData from "./NewsBoxData";
import SidebarPortal from '@plone/volto/components/manage/Sidebar/SidebarPortal';

const NewsBoxEdit = React.memo((props) => {
    const { selected, onChangeBlock, block, data } = props;

    return(
        <>
            <p>News Block</p>
            <SidebarPortal selected={selected}>
                <NewsBoxData 
                {...props}
                data={data}
                block={block}
                onChangeBlock={onChangeBlock}
                />
            </SidebarPortal>
        </>
    )
})

export default NewsBoxEdit;