import config from '@plone/volto/registry';

export const NewsBoxSchema = (props) => {
    return {
        title: 'News',
        fieldsets: [
            {
                id: 'default',
                title: 'Default',
                fields: ['newsurl', 'expiredate'],
            },
        ],

        properties: {
            newsurl: {
                title: 'Url',
                description: 'Start Url für die News',
                widget: 'internal_url',
                required: true,
            },
            expiredate: {
                title: 'Vergangene Events ausblenden',
                type: 'boolean',
            },
        },
        required: [],
    };
};

export default NewsBoxSchema;