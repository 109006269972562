import IsotopeEdit from "./Isotope/IsotopeEdit";
import IsotopeView from "./Isotope/IsotopeView";

import NewsBoxEdit from "./NewsBox/NewsBoxEdit";
import NewsBoxView from "./NewsBox/NewsBoxView";

import LightGalleryEdit from "./LightGallery/LightGalleryEdit";
import LightGalleryView from "./LightGallery/LightGalleryView";

import CalculatorEdit from "./Calculator/CalculatorEdit";
import CalculatorView from "./Calculator/CalculatorView";

import icon from '@plone/volto/icons/tune.svg';
import iconNews from '@plone/volto/icons/news.svg';
import lightGalleryIcon from '@plone/volto/icons/images.svg';
import calculatorIcon from '@plone/volto/icons/circle-plus.svg';

export const customBlocks = {
    isotope: {
        id: 'isotope',
        title: 'Personen',
        edit: IsotopeEdit,
        view: IsotopeView,
        icon: icon,
        group: 'special',
        restricted: false,
        mostUsed: false,
        sidebarTab: 1,
        security: {
          addPermission: [],
          view: [],
        },
      },
    news: {
        id: 'news',
        title: 'News',
        edit: NewsBoxEdit,
        view: NewsBoxView,
        icon: iconNews,
        group: 'special',
        restricted: false,
        mostUsed: false,
        sidebarTab: 1,
        security: {
          addPermission: [],
          view: [],
        },
      },
    lightGallery: {
        id: 'lightGallery',
        title: 'Light Gallery',
        edit: LightGalleryEdit,
        view: LightGalleryView,
        icon: lightGalleryIcon,
        group: 'media',
        restricted: false,
        mostUsed: true,
        sidebarTab: 1,
        security: {
          addPermission: [],
          view: [],
        },
      },
    calculator: {
        id: 'calculator',
        title: 'Rechner',
        edit: CalculatorEdit,
        view: CalculatorView,
        icon: calculatorIcon,
        group: 'special',
        restricted: false,
        mostUsed: false,
        sidebarTab: 1,
        security: {
          addPermission: [],
          view: [],
        },
      },
    };